<div class="nord-vpn-guide">
  <h1>Configure Nord VPN</h1>
  <div class="guide-steps">
    <div class="step-holder">
      <p>1. Login to Nord VPN using your credentials by using your web browser.</p>
      <img src="../../../../../../assets/images/nordvpn-login.png">
    </div>
    <div class="step-holder">
      <p>2. Once inside you will be redirected to the Nord VPN dashboard. From there, please navigate in the left sidebar menu under <b>“Services”</b> and choose Nord VPN option.</p>
      <img src="../../../../../../assets/images/nordvpn-dashboard.png">
    </div>
    <div class="step-holder">
      <p>3. This will open a new page. Scroll down to the end of the page where you can find the form to generate the token.</p>
      <img src="../../../../../../assets/images/nordvpn-token-form.png">
    </div>
    <div class="step-holder">
      <p>4. Click on the button <b>“Generate new token”</b>. This will open a new form pop-up asking to select an option for log in through Linux.
        Choose the option <b>“Doesn’t expire”</b> and click on the button <b>“Generate Token”</b>
      </p>
      <img src="../../../../../../assets/images/nordvpn-new-token.png">
    </div>
    <div class="step-holder">
      <p>5. This action will generate a new token for your user. Click on the <b>“Copy and close”</b> button, which will save this token and copy to your clipboard ready to be pasted into the application.</p>
      <img src="../../../../../../assets/images/nordvpn-copy-token.png">
    </div>
    <div class="step-holder">
      <p>6. You will see the notification that there was a new token generated and this token will be listed into the tokens list.</p>
      <img src="../../../../../../assets/images/nordvpn-list-token.png">
    </div>
    <div class="step-holder">
      <p>7. Next, login into the portal account, under the <b>Settings > Integrations</b>, click on the <b>“Settings”</b> button under the Nord VPN/Server Group Integration section
        and paste the copied token value into the field for token on the form . Then click <b>“Submit ”</b>.
        This way you have configured the Nord VPN account to work with the application.
      </p>
      <img src="../../../../../../assets/images/nordvpn-app-token.png">
    </div>
  </div>
</div>
