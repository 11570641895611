import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { BaseComponent } from 'app/@core/base/base.component';

@Component({
  selector: 'ngx-proceed-dialog',
  templateUrl: './proceed-dialog.component.html',
  styleUrls: ['./proceed-dialog.component.scss']
})
export class ProceedDialogComponent extends BaseComponent implements OnInit{

  @Input() message: string;
  @Input() showHeader: boolean = true; // Default to showing header
  @Input() showCancelButton: boolean = true; // Default to showing Cancel button
  @Input() proceedButtonText: string = 'Proceed'; // Default to "Proceed" button text

  constructor(private _dialogRef: NbDialogRef<ProceedDialogComponent>) {
    super();
   }

  ngOnInit(): void {
  }

  proceed(action: boolean) {
    this._dialogRef.close(action);
  }
}
