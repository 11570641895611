<div class="datepicker-holder">

    <nb-icon class="close-icon" icon="close" (click)="dialogRef.close()"></nb-icon>

    <div class="date">
        <nb-calendar [(date)]="date" (dateChange)="handleDateChange($event)"  [min]="minDate"></nb-calendar>
    </div>
    <div id="time">
        <div class="ranges-head">
            <p>Time</p>
        </div>
        <div class="scrollable" #rangeEntriesContainer>
            <div *ngFor="let range of timeRanges; let i = index">
                <p class="range-entry" (click)="selectRange(i)" [ngClass]="{'range-active' : i === rangeIndex}">{{range}}</p>
            </div>
        </div>
    </div>
</div>

<div class="actions">
    <button class="cancel mr-1" nbButton size="tiny" status="danger" (click)="dialogRef.close()">Cancel</button>
    <button [disabled]="!selectedDate" nbButton size="tiny" status="success" (click)="submit()">Submit</button>
</div>
