import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhonePipe } from './phone.pipe';
import { ConfirmRefreshDialogComponent } from './confirm-refresh-dialog/confirm-refresh-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { NB_TIME_PICKER_CONFIG, NbButtonModule, NbCalendarModule, NbCardModule, NbDatepickerModule, NbIconModule, NbInputModule, NbRadioModule, NbSelectModule, NbTimepickerModule, NbToggleModule } from '@nebular/theme';
import { NotificationsSidenavComponent } from './notifications-sidenav/notifications-sidenav.component';
import { NotificationBarComponent } from './notification-bar/notification-bar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomRecipientEditorComponent } from '../lead/profile/forms/custom-recipient-editor/custom-recipient-editor.component';
import {
  NbSpinnerModule,NbTooltipModule
} from '@nebular/theme'
import { BaseComponent } from 'app/@core/base/base.component';
import { NotificationGroupBarComponent } from './notification-group-bar/notification-group-bar.component';
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DocumentPreviewComponent } from './document-preview/document-preview.component';
import { ProposalSiteEditorComponent } from './proposal/proposal-site-editor/proposal-site-editor.component';
import { ProposalSignerDetailsComponent } from './proposal/proposal-signer-details/proposal-signer-details.component';
import { AddProposalFormComponent } from './proposal/add-proposal-form/add-proposal-form.component';
import { CoreModule } from 'app/@core/core.module';
import { SiteSaveChangeConfirmationComponent } from './proposal/proposal-site-editor/site-save-change-confirmation/site-save-change-confirmation.component';
import { DeleteProposalComponent } from './proposal/delete-proposal/delete-proposal.component';
import { SignedProposalsDeleteConfirmationComponent } from './proposal/proposal-site-editor/signed-proposals-delete-confirmation/signed-proposals-delete-confirmation.component';
import { NgxMaskModule } from 'ngx-mask';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { InitialsPipe } from './initials.pipe';
import { EmailValidationPipe } from './custom-pipes/email-validation.pipe';
import { RecipientFormComponent } from './reminder/recipient-form/recipient-form.component';
import { LeadContactsComponent } from './lead-contacts/lead-contacts.component';
import { LeadContactsSidebarComponent } from './lead-contacts-sidebar/lead-contacts-sidebar.component';
import { NgbdTooltipBasic } from './tooltip-custom/tooltip-basic';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TooltipViewComponent } from './tooltip-custom/tooltip-view/tooltip-view.component';
import { ToolTipRendererDirective } from './tooltip-custom/tool-tip-renderer.directive';
import { LeadContactsEditableComponent } from './lead-contacts-editable/lead-contacts-editable.component';
import { DatetimePickerCustomComponent } from './datetime-picker-custom/datetime-picker-custom.component';
import { TextHighlightDirective } from 'app/@core/utils/text-highlight.directive';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CustomSelectComponent } from './custom-select/custom-select.component';
import { CustomColumnTableListHtmlComponent } from './custom-column-table-list-html/custom-column-table-list-html.component';
import { SmtpHostValidationPipe } from './custom-pipes/smtp-host-validation.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { NotificationsAllComponent } from './notifications-all/notifications-all.component';
import { NotificationsSettingsComponent } from './notifications-settings/notifications-settings.component';
import { MatMenuModule } from '@angular/material/menu';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { TimeAgoPipe } from './time-ago.pipe';
import { ImportFileTableMappingComponent } from './import-file-table-mapping/import-file-table-mapping.component';
import { ProposalSiteManageComponent } from './proposal/proposal-site-editor/proposal-site-manage/proposal-site-manage.component';
import { ProposalSiteManageDevidedComponent } from './proposal/proposal-site-editor/proposal-site-manage-devided/proposal-site-manage-devided.component';
import { ProposalStatusHistoryComponent } from './proposal/proposal-status-history/proposal-status-history.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { MatRadioModule } from '@angular/material/radio';
import { ProceedDialogComponent } from './proceed-dialog/proceed-dialog.component';
@NgModule({
  declarations: [
    PhonePipe,
    ConfirmRefreshDialogComponent,
    ConfirmDialogComponent,
    NotificationsSidenavComponent,
    NotificationBarComponent,
    CustomRecipientEditorComponent,
    BaseComponent,
    NotificationGroupBarComponent,
    DocumentPreviewComponent,
    ProposalSiteEditorComponent,
    CustomSelectComponent,
    ProposalSignerDetailsComponent,
    AddProposalFormComponent,
    SiteSaveChangeConfirmationComponent,
    DeleteProposalComponent,
    SignedProposalsDeleteConfirmationComponent,
    InitialsPipe,
    EmailValidationPipe,
    SmtpHostValidationPipe,
    RecipientFormComponent,
    LeadContactsComponent,
    LeadContactsSidebarComponent,
    NgbdTooltipBasic,
    TooltipViewComponent,
    ToolTipRendererDirective,
    TextHighlightDirective,
    LeadContactsEditableComponent,
    DatetimePickerCustomComponent,
    ToolTipRendererDirective,
    CustomColumnTableListHtmlComponent,
    NotificationsAllComponent,
    NotificationsSettingsComponent,
    TimeAgoPipe,
    ImportFileTableMappingComponent,
    ProposalSiteManageComponent,
    ProposalSiteManageDevidedComponent,
    ProposalStatusHistoryComponent,
    MyProfileComponent,
    ProceedDialogComponent
  ],
  imports: [
    CommonModule,
    NbButtonModule,
    NbCardModule,
    NbIconModule,
    NbInputModule,
    NbSelectModule,
    NbToggleModule,
    NbRadioModule,
    ReactiveFormsModule,
    FormsModule,
    NbSpinnerModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    NbTooltipModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    MatPaginatorModule,
    NbDatepickerModule,
    MatTooltipModule,
    MatMenuModule,
    NbTimepickerModule,
    NgSelectModule,
    NbCalendarModule,
    MatCheckboxModule,
    CoreModule.forChild(),
    InfiniteScrollModule,
    NgxMaskModule.forRoot({
      showMaskTyped : false,
      // clearIfNotMatch : true
    }),
    MatRadioModule
  ],
  providers: [
    {
    provide:NB_TIME_PICKER_CONFIG,
    useValue:{}
    },
    EmailValidationPipe,
    SmtpHostValidationPipe,
  ],
  exports: [
    PhonePipe,
    ConfirmRefreshDialogComponent,
    NotificationsSidenavComponent,
    NotificationBarComponent,
    ReactiveFormsModule,
    FormsModule,
    CustomRecipientEditorComponent,
    NbSpinnerModule,
    BaseComponent,
    NotificationGroupBarComponent,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    MatTooltipModule,
    InitialsPipe,
    EmailValidationPipe,
    SmtpHostValidationPipe,
    LeadContactsComponent,
    LeadContactsSidebarComponent,
    NgbdTooltipBasic,
    TooltipViewComponent,
    ToolTipRendererDirective,
    TextHighlightDirective,
    LeadContactsEditableComponent,
    NbIconModule,
    NbButtonModule,
    NbCardModule,
    NbInputModule,
    NbSelectModule,
    NbTooltipModule,
    NbRadioModule,
    NgSelectModule,
    NbDatepickerModule,
    MatCheckboxModule,
    CustomSelectComponent,
    NgSelectModule,
    MatMenuModule,
    InfiniteScrollModule,
    TimeAgoPipe,
    ImportFileTableMappingComponent,
    ProposalStatusHistoryComponent,
    MatRadioModule
  ]
})
export class SharedModule { }

