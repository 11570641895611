import { Component } from '@angular/core'

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
        <span class="alert-title" *ngIf="isOffPeakHours()"><b>Note:</b> It's currently off-peak hours (11 PM - 7 AM).<br>Access is restricted for non-admin users.</span>
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-footer fixed class="d-none">
        <ngx-footer></ngx-footer>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent {

  isOffPeakHours(): boolean {
    const currentHour = new Date().getHours();
    return currentHour >= 23 || currentHour < 7;
  }
}
