export class NotifEvent {
  constructor(
    public title ?: string | null,
    public status ?:  any | null,
    public statusName ?: string | null,
    public code ?: string | null,
    public type ?: number | null,
    public typeName ?: string | null,
    public leadTypeName ?: string | null,
    public documentType ?: any | null,
    public tableRowId ?: number | null,
    public leadId ?: number | null,
    public isOpen ?: boolean | null,
    public hide ?: boolean | null,
    public isHidden ?: boolean | null,
    public icon ?: string | null,
    public notificationId ?: number | null,
    public envelopeId ?: string | null,
    public isLast ?: boolean | null,
    public CreatedDate ?: any | null,
    public fromApi ?: boolean | null,
    public timeAgoIso ?: boolean | null,
    public messageProcessingDelaySeconds ?: number | null,
    public isClosed ?: boolean | null,
    public id ?: number | null,
  ){}
}

export class NotificaitonAlert {
  constructor(
    public id ?: string | null,
    public key ?: any | null,
    public title ?: string | null,
    public timeAgo?: any | null,
    public timeAgoIso ?: any | null,
    public typeName ?: string | null,
    public statusName ?: string | null,
    public icon ?: string | null,
    public events: Array<NotifEvent> = [],
    public isIndividual?: boolean | null,
    public isRead ?: boolean | null,
    public isGroupOpen ?: boolean | null,
    public hide ?: boolean | null,
    public iconColor ?: string | null,
    public iconBg ?: string | null,
    public CreatedDate ?: any,
    public fromApi ?: boolean,
  ){}
}

export class SignedDocumentNotificationAlert {
  constructor(
    public id ?: number,
    public leadName?: string | null,
    public leadType?: number,
    public documentType?: string | null
  ){}
}

export class NotificationSettings {
  constructor(
    public usePushNotification ?: boolean | null,
    public useEmailNotification ?: boolean | null,
    public useSmsNotification ?: boolean | null
  ){}
}

export class NotificationRequest {
  constructor(
    public pageNumber ?: number,
    public pageSize ?: number,
    public sortDirection ?: string,
  ){}
}

export class NotificationApiBar {
  constructor(
    public NotificationId ?: number,
    public ParentNotificationKey ?: string,
    public Title ?: string,
    public Status ?: number,
    public Icon ?: string,
    public Type ?: number,
    public LeadId ?: number,
    public LeadTypeName ?: string,
    public DocumentType ?: string,
    public TableRowId ?: number,
    public EnvelopeId ?: string,
    public CreatedDate ?: any,
    public IsClosed ?: boolean,
    public Id ?: number,
  ){}
}

export class NotificationApiItem {
  constructor(
    public Id ?: number | string,
    public Key ?: string,
    public Title ?: string,
    public Status ?: number,
    public IsIndividual ?: boolean,
    public IsRead ?: boolean,
    public Events: Array<NotificationApiBar> = [],
    public CreatedDate ?: Date | string,
  ){}
}
