<nb-card>
    <nb-card-header>
        {{userName}} Login History <nb-icon class="close-icon" icon="close" (click)="closeDialog()"> </nb-icon>
        <button nbButton class="btn btn-secondary pull-right"
                size="tiny" status="danger" (click)="onClick()" [disabled]="selectedRows.length === 0 || isLoading" [nbSpinner]="isLoading">
            Delete Rows
        </button>
        <button nbButton status="warning" size="tiny" class="pull-right" [nbSpinner]="exportLoadingStatus == 1"
            [disabled]="exportLoadingStatus == 1 || isEmptyRows" (click)="onExport()">
            Export History
        </button>
    </nb-card-header>

    <nb-card-body>
        <ng2-smart-table
        [settings]="settings"
        [source]="source"
        (userRowSelect)="onRowSelect($event)"></ng2-smart-table>
    </nb-card-body>
</nb-card>
