<nb-card>
  <nb-card-header *ngIf="showHeader">
    Would you like to proceed?
  </nb-card-header>
  <nb-card-body>
    <div class="p-2">
      <p class="m-0 fs-6"><b>{{ message }}</b></p>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <button class="cancel" size="tiny" nbButton status="danger" (click)="proceed(false)" *ngIf="showCancelButton">Cancel</button>
    <button class="ml-1" size="tiny" nbButton status="success" (click)="proceed(true)">{{ proceedButtonText }}</button>
  </nb-card-footer>
</nb-card>
