<nb-card>
  <nb-card-header> {{ model.Id ? 'Edit Document' : 'Upload Document' }} <nb-icon class="close-icon" icon="close"
      (click)="dialogRef.close()"></nb-icon></nb-card-header>
  <nb-card-body>
    <div class="add-new-document" *ngIf="!newDocumentForm && model?.Files.length>0 && !editMode">
      <button (click)="newDocumentForm=!newDocumentForm">Upload new document</button>
    </div>
    <form [formGroup]="uploadForm" class="w-100 m-t dialog-form" *ngIf="model?.Files.length==0 || newDocumentForm">
      <label class="lbl-document-info">Document info</label>
      <div class="field">
        <label>Type</label>
        <mat-form-field appearance="outline" class="mb-10 w-100">
          <mat-select formControlName="DocumentType" id="documentTypes.Id" [disabled]="disableDropdown" placeholder="None" (selectionChange)="termsSelected($event)">
            <mat-option *ngFor="let documentTypes of documentTypes"
              [value]="documentTypes.Name">{{documentTypes.Name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="field">
        <label>Description</label>
        <mat-form-field appearance="outline" class="w-100">
          <textarea matInput formControlName="Description" matAutosizeMinRows=2 matAutosizeMaxRows=5 name="Description" (input)="onDescriptionChange()"></textarea>
        </mat-form-field>
      </div>

      <div *ngIf="!model.Id && !editMode" class="file-field">
        <div>
          <label>File</label>
          <br />
          <input nbInput fullwidth type="file" #fileInput (change)="upload($event.target.files)" accept=".doc,.docx,.xls,.xlsx,.pdf"/>
          <!-- <button nbButton *ngIf="isList" size="tiny" status="primary" class="pull-right mb-10 mt-10" (click)="saveDocument()" [disabled]="!model.Type || !fileAttached">Save Document</button> -->
        </div>
        <button nbButton size="tiny" status="primary" class="pull-right mb-10 mt-10" (click)="saveDocument()" *ngIf="model?.Files.length==0 && newDocumentForm && isList" [disabled]="!this.fileAttached || !this.uploadForm.get('DocumentType').value">Continue
          <nb-icon icon="arrow-forward-outline"></nb-icon>
        </button>
      </div>
      <button nbButton size="tiny" status="warning" class="pull-right btn-cancel" *ngIf="model?.Files.length > 0 && !isList" (click)="cancelTermsAndConditionsFileUpload();">Cancel</button>
      <button nbButton size="tiny" status="warning" class="pull-right btn-cancel" *ngIf="model?.Files.length && newDocumentForm && isList && showCancelBtn" (click)="newDocumentForm = !newDocumentForm">Cancel</button>
    </form>
    <!-- doc list -->
    <div class="documents-holder" *ngIf="model?.Files && model?.Files.length>0 && !disableDropdown && !editMode">
      <div class="field document-item" [ngClass]="{'border-b' : isList}" *ngFor="let document of model.Files; let i = index">
        <label *ngIf="isList">Upload Additional Documents</label>
        <div style="display: flex; align-items: center; justify-content: space-between; flex-grow: 1; padding-left: 10px">
          <div>
            <span *ngIf="document.id" [style.margin-right]="document && document.id ? '0' : '0.5rem'"
              style="margin-bottom: 5px">
              Name: <span style="color: #396e94;">{{ document.name ? document.name : 'N/A' }}</span>
            </span>
            <p *ngIf="document.id" class="mb-0 h-20">
              Type: {{ document.type ? document.type : 'N/A' }}
            </p>
          </div>
          <!-- <button *ngIf="!document || !document.id" nbButton size="tiny" status="primary" class="pull-right mb-10" (click)="saveDocument(i)" [disabled]="!model.Type">Save Document</button> -->
          <div style="flex: 0 0 auto; display: flex; gap: 10px;">
            <button *ngIf="document && document.id" nbButton size="tiny" status="warning" class="pull-right mb-10" (click)="editDocument(i)">Edit Document</button>
            <button *ngIf="document && document.id" nbButton size="tiny" status="danger" class="pull-right mb-10 ml-2" (click)="deleteUploadedDocument(i)">Delete Document</button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="editMode" class="editMode-btns">
      <button nbButton size="tiny" status="danger" (click)="cancelEdit()">Cancel</button>
      <button nbButton size="tiny" status="success" class="ml-2" (click)="saveDocument()" [nbSpinner]="saveInProgress" [disabled]="saveInProgress || uploadForm.invalid || !hasChanges">Save Changes</button>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <button class="cancel" size="tiny" nbButton status="danger" (click)="dialogRef.close()">Cancel</button>
    <button *ngIf="!isList" nbButton size="tiny" status="success" [nbSpinner]="saveInProgress"
      [disabled]="saveInProgress || uploadForm.invalid || !model.Files.length" (click)="submit()">
      Submit
    </button>

    <button *ngIf="isList" nbButton size="tiny" status="success" [nbSpinner]="saveInProgress"
      [disabled]="isUpdateDocument ? saveInProgress : saveInProgress || !enableSaveList || newDocumentForm" (click)="submitList()">
      Submit
    </button>
  </nb-card-footer>

</nb-card>
