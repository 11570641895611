import { FieldObject } from "./shared.model";

export class LeadObject {
  constructor(
    public Id?: number | null,
    public Key?: string | null,
    public Name?: string | null,
    public Fields?: Array<FieldObject> | null,
    public ExistingCharge?: number | null,
    public Type?: number | null,
    public TypeName?: string | null,
    public ExcludedContractsEndDate?: string | Date | null,
    public CurrentEndDate?: string | Date | null,
    public EffectiveCurrentEndDate?: string | Date | null,
    public AnnualUsage?: number | null,
    public Address?: string | null,
    public Birthday?: string | Date | null,
    public BrandId?: number | null,
    public BrandName?: string | null,
    public CalDate?: string | Date | null,
    public City?: string | null,
    public CompanyDetails?: LeadCompany | null,
    public CreatedDate?: string | Date | null,
    public CurrentStartDate?: string | Date | null,
    public ApplicantVendorId? : number | null,
    public ApplicantVendorName?: string | null,
    public CustomerVendorId?: number | null,
    public CustomerVendorName?: string,
    public CustomerExistingCharge?: number | null,
    public DateSigned?: string | Date | null,
    public DefaultSiteAddress?: string | null,
    public DefaultSiteCity?: string | null,
    public DefaultSiteId?: number | null,
    public DefaultSiteState?: string | null,
    public DefaultSiteZipCode?: string | null,
    public EffectiveAnnualUsage?: number | null,
    public IsAnnualUsageOverriden?: boolean | null,
    public IsArchived?: boolean | null,
    public LeadCurrentVendorName?: string | null,
    public SalesValue?: string | null,
    public SiteCount?: number | null,
    public SourceId?: number | null,
    public SourceName?: string | null,
    public Status?: number | null,
    public StatusName?: string | null,
    public Tags?: string | null,
    public AssignedToId?: number | null,
    public MostFrequentEndDate?: string | Date | null
  ){}
}

export class CreateLeadObject {
  constructor(
    public Name?: string | null,
    public City?: string | null,
    public Address?: string | null,
    public SalesValue?: number | null,
    public Status?: any | null,
    public Tags?: any | boolean | null,
    public Type?: any | null,
    public SourceId?: number | null,
    public Birthday?: string | null,
    public DateSigned?: string | boolean | null,
    public CallDate?: string | boolean | null,
    public CompanyDetails?: LeadCompany | null,
    public Contact?: ContactObject | null,
    public LeadSite?: CreateLeadSite | null,
    public InternalTags?: Array<tagEntry> | null,
    public AdditionalFields?: AdditionalFieldsObject | null
  ){}
}

export class LeadCompany {
  constructor(
    public Name ?: string | null,
    public Address ?: string | null,
    public Web ?: string | null,
    public Email ?: string | null,
    public Phone ?: string | null,
    public Note ?: string | null,
    public Tags ?: string | null,
    public LeadId ?: number | null,
  ) {}
}

export class CreateLeadSite {
  constructor(
    public Address ?: string | null,
    public City ?: string | null,
    public State ?: string | null,
    public ZipCode ?: string | null,
  ){}
}

export class LeadFormField {
  constructor(
    public Name ?: string | null,
    public Value ?: string | null,
    public Select ?: LeadFormFieldSelect | null,
  ){}
}

export class LeadFormFieldSelect {
  constructor(
    public IsSelected ?: boolean | null,
    public Index : number | null = 0,
  ){}
}

export class LeadFiledsData {
  constructor(
    public LeadFields ?: Array<LeadFormField> | null,
    public ContactFields ?: Array<LeadFormField> | null,
    public AdditionalFields ?: Array<LeadFormField> | null,
  ){}
}

export class LeadFormFileds {
  constructor(
    public lead ?: Array<string> | null,
    public contact ?: Array<string> | null,
  ){}
}

export class UpdateLeadAnnualUsageObject {
  constructor(
    public Id: number,
    public AnnualUsage: number
  ){}
}

export class ContactObject {
  constructor(
    public Id?: number | null,
    public CreatedDate?: string | Date | null,
    public LeadId?: number | null,
    public FirstName?: string | null,
    public FullName?: string | null,
    public LastName?: string | null,
    public Position?: string | null,
    public Company?: string | null,
    public Email?: string | null,
    public IsSelected?: boolean | null,
    public IsSigner?: boolean | null,
    public IsCustom?: boolean | null,
    public PhoneNumber?: string | null,
    public Tags?: string | null,
    public editMode?: boolean | null,
    public editName?: boolean | null,
    public IsPrimary ?: boolean | null
  ){}
}

export class CustomContactObject extends ContactObject {
  public editFirstName?: boolean | null;
  public editLastName?: boolean | null;
  public editTitle?: boolean | null;
  public editEmail?: boolean | null;
  public editPhoneNumber?: boolean | null;
}

export class RecipientObject {
  constructor(
    public Id : number | null = null,
    public LeadId: number | null = null,
    public FirstName: string | null = null,
    public LastName: string | null = null,
    public Position: string | null = null,
    public Company: string | null = null,
    public Email: string | null = null,
    public IsSelected: boolean | null = null,
    public IsSigner: boolean | null = null,
    public IsCustom: boolean | null = null,
    public PhoneNumber: string | null = null
  ){}
}

export class AssignLeadApplicant {
  constructor(
    public LeadIds: Array<number> = [],
    public ReassignToAgentId: number | null = null,
  ){}
}

export class AssignLeadHistory {
  constructor(
    public Id: number | null = null,
    public LeadId: number | null = null,
    public ReassignedFromAgentId: number | null = null,
    public ReassignedToAgentId: number | null = null,
    public ReassignedByUserId: number | null = null,
    public ReassignedFromAgentName: string | null = null,
    public ReassignedToAgentName: string | null = null,
    public ReassignedByUserName: string | null = null,
    public CreatedDate: any | null = null,
  ){}
}

export class tagEntry {
  public Id: any | null;
  public Name: string | null;
  public Color: string | null;
  public Selected?: boolean;
}

export class AdditionalFieldsObject {
  constructor(
    public BrandId?: number | null,
    public BrandName?: string | null,
    public ApplicantVendorId?: number | null,
    public ApplicantVendorName?: string | null,
    public CurrentEndDate?: string | null,
    public ExistingCharge?: number | null,
    public AnnualUsage?: number | null
  ){}
}
