import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { NbToastrService, NbDialogService, NbDialogRef } from '@nebular/theme';
import { UploadedFileListObject } from 'app/@core/models/file.model';
import { LookupObject } from 'app/@core/models/lookup.model';
import { CreateCustomProposalObject, ProposalObject } from 'app/@core/models/proposal.model';
import { TranslateService, DocumentService } from 'app/@core/services';
import { LeadSiteService } from 'app/@core/services/lead-site.service';
import { LeadService } from 'app/@core/services/lead.service';
import { LookupService } from 'app/@core/services/lookup.service';
import { ProposalService } from 'app/@core/services/proposal.service';
import { UploadEditorComponent } from 'app/pages/lead/profile/forms/upload-editor/upload-editor.component';
import { lightFormat, addMonths } from 'date-fns';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { DocumentPreviewComponent } from '../../document-preview/document-preview.component';
import { saveAs } from 'file-saver'
import { ProposalSiteEditorComponent } from '../proposal-site-editor/proposal-site-editor.component';
import { LeadSiteObject, LeadSiteTableObject } from 'app/@core/models/lead-site.model';
import { ProposalStatus } from 'app/@core/enums/proposal-status';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { EmailValidationPipe } from '../../custom-pipes/email-validation.pipe';
import { ContactObject } from 'app/@core/models/lead.model';
import { CustomRecipientEditorComponent } from 'app/pages/lead/profile/forms/custom-recipient-editor/custom-recipient-editor.component';
import { DatetimePickerCustomComponent } from '../../datetime-picker-custom/datetime-picker-custom.component';
import { ProposalSiteManageComponent } from '../proposal-site-editor/proposal-site-manage/proposal-site-manage.component';

@Component({
  selector: 'ngx-add-proposal-form',
  templateUrl: './add-proposal-form.component.html',
  styleUrls: ['./add-proposal-form.component.scss']
})
export class AddProposalFormComponent implements OnInit {

  /**
   * Inputs/Outputs
   */
  @Input() leadId: number;
  @Input() title: string;
  @Input() confirmToDeleteSignedProposals: boolean = false;

  /**
   * Public variables
   */
  proposalForm: FormGroup;
  vendorList: Array<LookupObject> = new Array<LookupObject>();
  productList: Array<LookupObject> = new Array<LookupObject>();
  signedProposals: Array<ProposalObject> = new Array<ProposalObject>();
  filteredProductList: Array<LookupObject> = new Array<LookupObject>();
  companyPrice: number;
  vendorPrice: number;
  currentAnnualUsage: number = 0;
  selectedSites: Array<LeadSiteTableObject> = new Array<LeadSiteTableObject>();
  loading: boolean = false;
  customProposal: CreateCustomProposalObject = new CreateCustomProposalObject();
  inProgress: boolean = false;
  isDocumentUploaded: boolean = false;
  uploadedDocumentName: string;
  docId: number;
  uploadedDocList: Array<UploadedFileListObject> = new Array<UploadedFileListObject>();
  endDate: string;
  leadContacts: Array<ContactObject> = new Array<ContactObject>();
  loadingContacts: boolean = false;
  isContactSelected: boolean = false;
  precision: number = 0;
  vendorPriceValid: boolean = true;
  companyPriceValid: boolean = true;
  termValid: boolean = true;

  constructor(
    private _router: Router,
    private _translateService: TranslateService,
    private _formBuilder: FormBuilder,
    private _lookupService: LookupService,
    private _leadService: LeadService,
    private _leadSiteService: LeadSiteService,
    private _toastrService: NbToastrService,
    private _proposalService: ProposalService,
    private emailValidationPipe: EmailValidationPipe,
    private _dialogService: NbDialogService,
    private _documentService: DocumentService,
    private _datePipe: DatePipe,
    public dialogRef: NbDialogRef<AddProposalFormComponent>,
    private _cdRef: ChangeDetectorRef,
  ) {
    _translateService.init();
    this._initProposalForm();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    this._subscribeToVendorList();
    this._subscribeToProductList();
    this.getCurrentAnnualUsage();
    this.getLeadSites();
    this.getLeadContacts(false);

    if (this.confirmToDeleteSignedProposals) {
      this.getOngoingSignedProposals();
    }

    this._subscribeToVendorFormControlValueChanges();
    this._subscribeToStartDateFormControlValueChanges();
    this._subscribeToTermFormControlValueChanges();
    this._subscribeToContactFormControlValueChanges();

    this.proposalForm.get('signedDate').valueChanges.subscribe(
      data => {
        console.log(data)
      }
    )
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Function to init proposal form
   */
  private _initProposalForm() {
    this.proposalForm = this._formBuilder.group({
      vendor: new FormControl(null, Validators.required),
      product: new FormControl(null, Validators.required),
      vendorPrice: new FormControl(null, Validators.required),
      companyPrice: new FormControl(null, Validators.required),
      createdDate: new FormControl(null),
      signedDate: new FormControl(null, Validators.required),
      contact: new FormControl(),
      firstName: new FormControl(null),
      lastName: new FormControl(null),
      title: new FormControl(null),
      email: new FormControl(null, [Validators.email]),
      confirmationDate: new FormControl(null),
      startDate: new FormControl(null, Validators.required),
      term: new FormControl(null, Validators.required),
      monthlyRevenue: new FormControl(null),
      termRevenue: new FormControl(null),
      excludeFromReporting: new FormControl(false),
      requestInitialFees: new FormControl(null, Validators.required)
    });
  }

   /**
 * Checks if the specified form control is invalid
 * @param controlName - The name of the control to check
 * @returns boolean - True if the control is invalid and has been touched, otherwise false
 */
  isControlInvalid(controlName: string): boolean {
    const control = this.proposalForm.get(controlName);
    return control && (control.touched && control.invalid);
  }

  emailInputChanged() {
    let val = this.proposalForm.get('email').value;
    this.proposalForm.get('email').setErrors(
      val && this.emailValidationPipe.transform()(new FormControl(val)) ? { invalidFormat: true } : null);
  }

  /**
   * Function to handle blur event for numeric input
   */
  onNumericInputBlur(controlName: string, event: any) {
    const inputValue = event.target.value;
    const control = this.proposalForm.get(controlName);
    this[ controlName + 'Valid' ] = !!inputValue;

inputValue && inputValue.charAt(0) === '0'
  ? (control.setErrors({ zeroFirstChar: true }), event.target.classList.add('invalid-input'))
  : (inputValue
      ? (control.patchValue(inputValue), control.setErrors(null), event.target.classList.remove('invalid-input'))
      : (control.patchValue(null), control.setErrors({ required: true }), event.target.classList.add('invalid-input')));
  }

  /**
   * Function to subscribe to vendors
   */
  private _subscribeToVendorList() {
    this.loading = true;
    this._lookupService.getCustomerVendors().pipe(take(1)).subscribe({
      next: (response: Array<LookupObject>) => {
        this.vendorList = response;
        this.loading = false;
      },
      error: (error) => {
        this._toastrService.danger(error.error.Content);
        this.loading = false;
      }
    })
  }

  /**
   * Function to subscribe to products
   */
  private _subscribeToProductList() {
    this.loading = true;
    this._lookupService.getProducts().pipe(take(1)).subscribe({
      next: (response: Array<LookupObject>) => {
        this.productList = response;
        this.loading = false;
      },
      error: (error) => {
        this._toastrService.danger(error.error.Content);
        this.loading = false;
      }
    })
  }

  /**
   * Function to subscribe to vendor form control value changes
   */
  private _subscribeToVendorFormControlValueChanges() {
    this.proposalForm.get('vendor').valueChanges.subscribe(
      (selectedVendor) => {
        this.filteredProductList = this.productList.filter((product) => product.LookupTableId === selectedVendor)
        this.proposalForm.get('product').setValue(null)
      }
    )
  }

  /**
   * Function to subscribe to start date form control value changes
   */
  private _subscribeToStartDateFormControlValueChanges() {
    this.proposalForm.get('startDate').valueChanges.subscribe(
      (data) => {
        let startDate = data;
        let term = this.proposalForm.get('term').value;
        if (startDate && term) {
          this.endDate = lightFormat(addMonths(startDate, term), 'MM/dd/yyyy');
        }
        else if(startDate===  null){
          this.endDate = 'N/A';
        }
      }
    )
  }

  /**
   * Function to subscribe to term form control value changes
   */
  private _subscribeToTermFormControlValueChanges() {
    this.proposalForm.get('term').valueChanges.subscribe(
      (data) => {
        let term = data;
        let startDate = this.proposalForm.get('startDate').value;
        if (startDate && term) {
          this.endDate = lightFormat(addMonths(startDate, term), 'MM/dd/yyyy');
        }
      }
    )
  }

  /**
   * Function to subscribe to contact form control value changes
   */
  private _subscribeToContactFormControlValueChanges() {
    this.proposalForm.get('contact').valueChanges.subscribe(
      (data) => {
        if (data) {
          this.proposalForm.get('firstName').setValue(data.FirstName);
          this.proposalForm.get('lastName').setValue(data.LastName);
          this.proposalForm.get('title').setValue(data.Position);
          this.proposalForm.get('email').setValue(data.Email);
          this.isContactSelected = true;
        }
      }
    )
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Function to translate labels
   */
  public translate(key) {
    return this._translateService.translate(key)
  }

  /**
   * Function to get lead sites
   */
  getLeadSites() {
    this._leadSiteService.getLeadSites(this.leadId).pipe(take(1)).subscribe({
      next: (response: Array<LeadSiteObject>) => {
        this.selectedSites = response;
      },
      error: (error) => {
        this._toastrService.danger(error.error.Content);
      }
    })
  }

  /**
   * Function to get lead contacts
   */
  getLeadContacts(preselectLastContact: boolean): void {
    this.loadingContacts = true;
    this._leadService.getLeadContacts(this.leadId).pipe(take(1)).subscribe({
      next: (response: Array<ContactObject>) => {
        this.leadContacts = response;
        this.proposalForm.get('contact').setValue(preselectLastContact ? this.leadContacts[this.leadContacts.length - 1] : this.leadContacts[0]);
        this.loadingContacts = false;
      },
      error: (error) => {
        this._toastrService.danger(error.error.Content);
        this.loadingContacts = false;
      }
    })
  }

  /**
   * Function to open add new contact form
   */
  addContact(): void {
    let contact: ContactObject = new ContactObject();
    contact.LeadId = this.leadId;

    this._dialogService.open(CustomRecipientEditorComponent, {
        context: {
          model: contact
        },
        closeOnEsc: false,
        closeOnBackdropClick: false
      })
      .onClose.subscribe((response) => {
        if(response) {
          this.getLeadContacts(true);
        }
      })
  }

  /**
   * Function to get current annual usage
   */
  getCurrentAnnualUsage(): void {
    this.loading = true;
    this._leadService.getCurrentAnnualUsage(this.leadId).pipe(take(1)).subscribe({
      next: (response: number) => {
        this.currentAnnualUsage = response;
        this.calculateMonthlyRevenue();
        this.loading = false;
      },
      error: (error) => {
        this._toastrService.danger(error.error.Content);
        this.loading = false;
      }
    })
  }

  /**
   * Function to get ongoing signed proposals
   */
  getOngoingSignedProposals(): void {
    this.loading = true;
    this._proposalService.getOngoingProposals(this.leadId).pipe(take(1)).subscribe({
      next: (response: Array<ProposalObject>) => {
        this.loading = false;
        this.signedProposals =response.filter(x =>x.Status == ProposalStatus.Signed || x.Status == ProposalStatus.WaitingForConfirmation); //response.filter(x => x.Status != ProposalStatus.WaitingForConfirmation);
      },
      error: (error) => {
        this.loading = false;
        this._toastrService.danger(error.error.Content);
      }
    })
  }

  /**
   * Function to handle vendor price value change
   */
  vendorPriceChanged(event) {
    this.vendorPrice = event;
    this.updatePrecision()
  }

  /**
   * Function to handle company price value change
   */
  companyPriceChanges(event) {
    this.companyPrice = event;
    this.updatePrecision()
    this.calculateMonthlyRevenue();
  }

  /**
   * Function to calculate Monthly Revenue
   */
  calculateMonthlyRevenue() {
    if ((!isNaN(this.companyPrice) && this.companyPrice != null) && this.currentAnnualUsage != null && !isNaN(this.currentAnnualUsage)) {
      const monthlyUnit = this.currentAnnualUsage/12

      if (monthlyUnit !== 0) {
          this.proposalForm.get('monthlyRevenue').setValue((this.companyPrice * monthlyUnit).toFixed(Number.isInteger(this.companyPrice * monthlyUnit) ? 0 : 2))
          this.calculateTotalRevenue();
      } else {
        this.proposalForm.get('monthlyRevenue').setValue(null);
      }
    }
  }

   /**
   * Function to round value
   */
   round(value): number {
    let precisionNumber = Math.pow(10, this.precision)

    let epsolon = Number.EPSILON
    let rounded = Math.round((value + epsolon) * precisionNumber) / precisionNumber
    return rounded
  }

  /**
   * Function to update precision
   */
  updatePrecision(): void {
    let companyParts = (this.companyPrice || 0).toString().split('.');
    let companyPrecision = companyParts.length > 1 ? companyParts[1].length : 0;

    let vendorParts = (this.vendorPrice || 0).toString().split('.');
    let vendorPrecision = vendorParts.length > 1 ? vendorParts[1].length : 0;

    this.precision = Math.max(companyPrecision, vendorPrecision);
  }


  /**
   * Function to handle term value change
   */
  termChanged(event) {
    this.setCalculatedTotalRevenue(event);

    let term = event;
    if(isNaN(term) || term === null) {
      this.endDate='N/A';
    }
    else {
      let startDate = this.proposalForm.get('startDate').value;
      if (startDate && term) {
        this.endDate = lightFormat(addMonths(startDate, term), 'MM/dd/yyyy');
      }
    }
  }
/**
   * Function to prevent entering 0 for the term
   */
  preventLeadingZero(event: KeyboardEvent) {
    // Check if the pressed key is 0 and if the input value is currently empty
    if (event.key === '0' && (event.target as HTMLInputElement).value === '') {
      event.preventDefault();
    }
  }

  /**
   * Function to calculate Total Revenue
   */
  calculateTotalRevenue() {
    let term = this.proposalForm.get('term').value;
    if (!isNaN(term) && term != null) {
      this.setCalculatedTotalRevenue(term);
    }
  }

  /**
   * Function to bind calculated Total Revenue
   */
  setCalculatedTotalRevenue(term: number) {
    const monthlyUnit = this.currentAnnualUsage/12
    if (monthlyUnit !== 0) {
        this.proposalForm.get('termRevenue').setValue((this.companyPrice * monthlyUnit * term).toFixed(Number.isInteger(this.companyPrice * monthlyUnit * term) ? 0 : 2))
    } else {
      this.proposalForm.get('termRevenue').setValue(null)
    }
  }

  /**
   * Function to manage sites
   */
  manageSites() {
    this._dialogService
      .open(ProposalSiteManageComponent, {
        context: {
          proposal: { Sites: this.selectedSites },
          customConvert: true,
          leadId: this.leadId,
          loadProposalSites: false,
          isNewProposal: true
        },
        closeOnEsc: false,
        closeOnBackdropClick: false
      })
      .onClose.subscribe((response) => {
        if (response) {
          this.selectedSites = response.Sites;
          this.currentAnnualUsage = response.AnnualUsage != null ? response.AnnualUsage : 0;
        }
      })
  }

  /**
   * Function to open upload document editor
   */
  openUploadDocumentEditor(toList: boolean) {
    this._dialogService.open(UploadEditorComponent, {
      context: {
        leadId: this.leadId,
        isList: toList,
        enableTerms: false,
        showCancelBtn: toList
      },
      closeOnEsc: false,
      closeOnBackdropClick: false
    })
      .onClose.subscribe((result) => {
        if (result) {
          if (toList) {
            result.forEach(el => {
              let file = {
                id: el.Id,
                name: el.FileName,
                url: el.DocumentUrl,
                type: el.Type
              }
              this.uploadedDocList.push(file)
            });

          } else {
            this._toastrService.success(`Successfully uploaded`)
            this.isDocumentUploaded = true;
            this._cdRef.detectChanges();
            this.uploadedDocumentName = result.fileName;
            this.docId = result.docId;
          }
        }
      })
  }

  /**
   * Function to view document
   */
  viewDocument() {
    this._dialogService.open(DocumentPreviewComponent, {
      context: {
        isTemplatePreview: false,
        isDocusignDocument: false,
        fileName: this.uploadedDocumentName,
        documentId: this.docId,
        leadId: this.leadId
      },
      dialogClass: 'model-full'
    })
  }

  /**
   * Function to view document from list
   */
  viewDocumentFromList(document) {
    this._dialogService.open(DocumentPreviewComponent, {
      context: {
        isTemplatePreview: false,
        isDocusignDocument: false,
        fileName: document.name,
        documentId: document.id,
        leadId: this.leadId
      },
      dialogClass: 'model-full'
    })
  }

  /**
   * Function to download file
   */
  downloadFile(leadId: number, fileId: number, fileName: string) {
    this._documentService.downloadFileFromBlobStorage(leadId, fileId).pipe(take(1)).subscribe({
      next: (data: Blob) => {
        saveAs(data, fileName);
      },
      error: (error) => {
        this._toastrService.danger(error.error.Content);
      }
    });
  }

  /**
   * Function to delete uploaded document
   */
  deleteUploadedDocument(fileId: number, isList: boolean) {
    this.inProgress = true;

    this._documentService.deleteFileFromBlobStorage(this.leadId, fileId).pipe(take(1)).subscribe({
      next: (response: string) => {
        this._toastrService.success(response);
        if (isList) {
          // Only remove from the list
          const docIndex = this.uploadedDocList.findIndex(el => el.id === fileId);
          if (docIndex > -1) {
            this.uploadedDocList.splice(docIndex, 1);
          }
        } else {
          // Only reset the single document properties
          this.docId = null;
          this.uploadedDocumentName = null;
          this.isDocumentUploaded = false;
        }

        this._cdRef.detectChanges();
        this.inProgress = false;
      },
      error: (error) => {
        this._toastrService.danger(error.error.Content);
        this.inProgress = false;
      }
    });
  }

  /**
   * Function to delete uploaded documents from list
   */
  deleteUploadedDocumentList() {
    this.inProgress = true;
    let ids = [];
    if (this.docId != null) {
      ids.push(this.docId);
      this.docId = null;
    }
    this.uploadedDocList.forEach(el => {
      if (el.id) {
        ids.push(el.id);
      }
    })
    if (ids && ids.length > 0) {
      this._documentService.deleteMultipleFilesFromBlobStorage(this.leadId, ids).pipe(take(1)).subscribe({
        next: (response: string) => {
          this.uploadedDocList = [];
          this.inProgress = false;
          this.dialogRef.close();
        },
        error: (error) => {
          this._toastrService.danger(error.error.Content);
          this.inProgress = false;
          this.dialogRef.close();
        }
      });
    }
  }

  /**
   * Function to submit form
   */
  submit() {
    this.customProposal.CompanyPrice = this.proposalForm.get('companyPrice').value;
    this.customProposal.ProductId = this.proposalForm.get('product').value;
    this.customProposal.ContactId = this.proposalForm.get('contact').value.Id;
    this.customProposal.Email = this.proposalForm.get('email').value;
    this.customProposal.FirstName = this.proposalForm.get('firstName').value;
    this.customProposal.LastName = this.proposalForm.get('lastName').value;
    this.customProposal.Term = this.proposalForm.get('term').value;
    this.customProposal.Title = this.proposalForm.get('title').value;
    this.customProposal.VendorPrice = this.proposalForm.get('vendorPrice').value;
    this.customProposal.ExcludeFromReporting = this.proposalForm.get('excludeFromReporting').value;
    this.customProposal.LeadId = Number(this.leadId);
    this.customProposal.CreatedDate = this.proposalForm.get('createdDate').value != null ? moment(this.proposalForm.get('createdDate').value).utc().format() : moment(new Date()).format();
    this.customProposal.SignedDate = this.proposalForm.get('signedDate').value != null ? moment(this.proposalForm.get('signedDate').value).utc().format() : moment(new Date()).utc().format();
    this.customProposal.StartDate = moment(this.proposalForm.get('startDate').value).utc().format();
    this.customProposal.ConfirmationDate = this.proposalForm.get('confirmationDate').value != null ? moment(this.proposalForm.get('confirmationDate').value).utc().format() : null;
    this.customProposal.EndDate = moment(this.endDate).utc().format();
    this.customProposal.Sites = this.selectedSites.length > 0 ? this.selectedSites.map((x) => x.Id) : null;
    this.customProposal.AnnualUsage = this.currentAnnualUsage != null ? this.currentAnnualUsage : 0;
    this.customProposal.CustomConvertDocumentId = this.docId;
    this.customProposal.IsCustomConverted = true;
    this.customProposal.MonthlyRevenue = this.proposalForm.get('monthlyRevenue').value;
    this.customProposal.TermRevenue = this.proposalForm.get('termRevenue').value;
    this.customProposal.CustomConvertDocumentId = this.docId;
    this.customProposal.IsCustomConverted = true;
    this.customProposal.RequestInitialFees = this.proposalForm.get('requestInitialFees').value;
    this.customProposal.VendorId = this.proposalForm.get('vendor').value;

    this.customProposal.DeleteSignedProposals=true;
    this.inProgress = true;
    this.createManualProposal();

    // if(this.signedProposals.length > 0) {
    //   this._dialogService.open(SignedProposalsDeleteConfirmationComponent, {
    //     context: {
    //       ongoingSignedProposals: this.signedProposals,
    //     },
    //   })
    //   .onClose.subscribe((result) => {
    //     if (result==null) {return;}

    //     this.inProgress = true;
    //     this.customProposal.DeleteSignedProposals =result;
    //     this.customProposal.ProposalIdsToDelete = result?
    //       this.signedProposals.filter((proposal: ProposalObject) => proposal).map(proposal=>proposal.Id):
    //       new Array<number>();

    //     this.createManualProposal();
    //   })
    // }
    // else {
    //   this.inProgress = true;
    //   this.createManualProposal();
    // }
  }

  /**
   * Function to call api to create manual proposal
   */
  createManualProposal(): void {
    this._proposalService.createManualProposal(this.customProposal).pipe(take(1)).subscribe({
      next: (response: string) => {
        this._toastrService.success("Order successfully created");
        this.inProgress = false;
        this.dialogRef.close(true);

        this._router.navigate(['pages/customer/' + this.leadId + '/history'], { queryParams: { id: response } })
      },
      error: (error) => {
        this._toastrService.danger(error.error.Content);
        this.inProgress = false;
      }
    });
  }

  openCustomDateTimePicker(){
    this._dialogService.open(DatetimePickerCustomComponent, {
      context: {
        patchDate: this.proposalForm.get('signedDate').value
      },
      closeOnEsc: false,
      closeOnBackdropClick: false,
    })
    .onClose.subscribe((response) => {
      if (response) {
        this.proposalForm.get('signedDate').setValue(this._datePipe.transform(response, 'M/d/yyyy h:mm a'	));
      }
    })
  }
}
