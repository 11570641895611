import { HttpClient, HttpHeaders } from '@angular/common/http'
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core'
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { NbDialogRef, NbToastrService } from '@nebular/theme'
import { BaseComponent } from 'app/@core/base/base.component'
import { UploadDocumentObject } from 'app/@core/models/file.model'
import { TranslateService } from 'app/@core/services'
import { environment } from 'environments/environment'

@Component({
  selector: 'ngx-upload-editor',
  templateUrl: './upload-editor.component.html',
  styleUrls: ['./upload-editor.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class UploadEditorComponent extends BaseComponent implements OnInit {
  @Input() leadId: any
  @Input() file: any = {}
  @Input() isList: boolean
  @Input() enableTerms: boolean
  @Input() showCancelBtn: boolean

  documentTypes: any = []
  saveInProgress: boolean = false
  model: any
  disableDropdown: boolean = false;
  uploadForm: UntypedFormGroup
  fileAttached: any;
  @ViewChild('fileInput')
  myFileInput: ElementRef;
  fileList: Array<any> = [];

  listValidation = {
    term: false,
    attach: false,
    filesLength: 0
  }

  enableSaveList: boolean = false;
  isUpdateDocument: boolean = false;
  descriptionChanged: boolean = false;
  newDocumentForm:boolean = true;
  editDocumentIndex: number | null = null;
  editMode: boolean = false;
  hasChanges: boolean = false;
  initialValues: any;

  constructor(
    public dialogRef: NbDialogRef<UploadEditorComponent>,
    private toastrService: NbToastrService,
    private http: HttpClient,
    private cdRef: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    translateService: TranslateService
  ) {
    super()
    this.uploadForm = this.initFormGroup()
  }

  initFormGroup() {
    return this.formBuilder.group({
      DocumentType: new UntypedFormControl('', this.removeAuthoTuched ),
      Description: new UntypedFormControl('')
    });
  }
  removeAuthoTuched(control: AbstractControl): { [key: string]: any } | null {
    const value = control.value?.trim();
    const valid = value !== null && value !== '';
    return valid ? null : { required: true };
  }

  private _patchFormValues() {
    this.uploadForm.patchValue({
      DocumentType: this.model ? this.model.Type : null,
      Description: this.model ? this.model.Description : null

    });
    this.cdRef.detectChanges();
  }
  subscribeToFormChanges() {
    if (!this.editMode) {
      this.uploadForm.valueChanges.subscribe(
        value => {
          if (this.uploadForm.get('DocumentType').value && this.fileAttached) {
            this.tryToSaveDocument();
          }
        }
      )
    }
  }

  ngAfterViewChecked() {
    this.enableSaveList = this.listValidation.filesLength > 0 && !this.listValidation.term && !this.listValidation.attach;
    if ((this.uploadForm.get('DocumentType').value
        && !this.fileAttached) || (!this.uploadForm.get('DocumentType').value
        && this.fileAttached)) {
        this.enableSaveList = false;
    }

    if (this.model.Id && (this.isUpdateDocument || this.descriptionChanged)) {
      this.enableSaveList = true;
    }

    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    this.subscribeToFormChanges();

    this.model = Object.assign({}, this.file || {})

    this.isUpdateDocument = this.model.Id ? true : false;

    this.model.Files = []
    this.fileList = []
    this._patchFormValues();
    this.http.get(`${environment.baseApiUrl}/lookup/DocumentType`).subscribe((result: any) => {
      this.documentTypes = result;

      this.disableDropdown = false;

      if (this.isList) {
        if (!this.enableTerms) {
          let termsIndex = this.documentTypes.findIndex(el => el.Name === 'Terms and Conditions Document');
          this.documentTypes.splice(termsIndex, 1);
        }
      } else {
        this.model.Type = 'Terms and Conditions Document'
        this.uploadForm.get('DocumentType').patchValue('Terms and Conditions Document');

        this.disableDropdown = true;
      }
    })
  }

  onDescriptionChange() {
    this.descriptionChanged = true;
    if(this.editMode) {
      this.checkForChanges();
    }
  }

  saveDocument() {
    this.editMode = false;
    const file = {
      id: this.editDocumentIndex !== null ? this.model.Files[this.editDocumentIndex].id : this.model.Files.length + 1,
      url: null,
      type: this.uploadForm.get('DocumentType').value,
      name: this.fileAttached.name,
      description: this.uploadForm.get('Description').value,
    };

    if (this.editDocumentIndex !== null) {
      this.model.Files[this.editDocumentIndex] = file;
      this.editDocumentIndex = null;
    } else {
      this.model.Files.push(file);
    }

    this.model.Type = this.isList ? null : this.uploadForm.get('DocumentType').value;
    this.model.Description = this.isList ? null : this.uploadForm.get('Description').value;
    this.fileAttached = null;
    this.myFileInput !== undefined ? this.myFileInput.nativeElement.value = "" : '';

    this.uploadForm.get('DocumentType').setValue(this.isList ? null : this.uploadForm.get('DocumentType').value);
    this.uploadForm.get('Description').setValue(this.isList ? null : this.uploadForm.get('Description').value);

    this.listValidation.filesLength = this.model.Files.length;
    this.listValidation.attach = false;
    this.listValidation.term = false;

    this.newDocumentForm = false;

    this.cdRef.detectChanges();
  }
  upload(files) {
    const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

    if (files.length > 0) {
      const file = files[0];

      if (!allowedTypes.includes(file.type)) {
        this.toastrService.danger('Invalid file type. Please upload a Word, Excel, or PDF document.');
        return;
      }

      if (!this.isList) {
        this.model.Files = [];
        this.model.Files.push(files[0])
      } else {
        if (!this.fileAttached) {
          this.fileList.push(files[0])
        }
        this.fileAttached = files[0];
        this.tryToSaveDocument();
      }
    }
  }

  tryToSaveDocument() {
    if (this.fileAttached && this.uploadForm.get('DocumentType').value && !this.editMode) {
      this.listValidation.attach = true;
    } else {
      this.listValidation.attach = false;
    }
  }

  termsSelected(event: any) {
    if(!this.editMode) {
      this.listValidation.attach = true;

    // this.isUpdateDocument = true;

    this.tryToSaveDocument();
    } else if(this.editMode) {
      this.checkForChanges();
    }
  }

  deleteUploadedDocument(i: number) {
    this.model.Files.splice(i, 1);
    this.fileList.splice(i, 1);
    this.listValidation.filesLength = this.model.Files.length;
  }

  submit() {
    if (this.saveInProgress) return

    this.saveInProgress = true
    if (this.model.Id) {
      let payLoad = Object.assign({}, this.model)
      payLoad.contextMenu = null
      this.http.put(`${environment.baseApiUrl}/lead/${this.leadId}/files/${this.model.Id}`, payLoad).subscribe(
        (result) => {
          if (!this.isList) {
            this.dialogRef.close(true)
          }
        },
        (error) => {
          console.error(error)
          this.toastrService.danger(`Error during update: ${error}`)
          this.saveInProgress = false
        },
        () => {
          this.saveInProgress = false
        }
      )
    } else {
      const formData = new FormData()
      formData.append('LeadId', this.leadId)
      formData.append('Name', this.model.Name)
      formData.append('Type', this.uploadForm.get('DocumentType').value)
      if (this.uploadForm.get('Description').value) {
        formData.append('Description', this.uploadForm.get('Description').value)
      }

      for (let i = 0; i < this.model.Files.length; i++) {
        let fileDetaisl = {
          'fileType': this.uploadForm.get('DocumentType').value,
          'documentName': this.model.Name
        };

        formData.append(JSON.stringify(fileDetaisl), this.model.Files[i])
      }

      this.http.post(`${environment.baseApiUrl}/document/${this.leadId}/files/blob`, formData).subscribe(
        (result: any) => {
          let uploadedDocumentResponse: any = {
            fileName: result.FileName,
            docId: result.Id,
            docUrl: result.DocumentUrl
          }
          this.dialogRef.close(uploadedDocumentResponse)
        },
        (error) => {
          console.error(error)
          this.toastrService.danger(`Error during upload: ${error.error.Content}`)
          this.saveInProgress = false
        },
        () => {
          this.saveInProgress = false
        }
      )
    }
  }

  submitList() {
    this.model.Type = this.uploadForm.get('DocumentType').value;
    this.model.Description = this.uploadForm.get('Description').value;
    if (this.saveInProgress) return
    this.saveInProgress = true
    if (this.model.Id) {
      let payLoad = Object.assign({}, this.model)
      payLoad.contextMenu = null

      this.http.put(`${environment.baseApiUrl}/lead/${this.leadId}/files/${this.model.Id}`, payLoad).subscribe(
        (result) => {
          // this.toastrService.success(`Successfully ${this.model.Id ? 'saved' : 'uploaded'}`)
          this.dialogRef.close(this.model.Files)
        },
        (error) => {
          console.error(error)
          this.toastrService.danger(`Error during update: ${error}`)
          this.saveInProgress = false
        },
        () => {
          this.saveInProgress = false
        }
      )
    } else {
      let fd: FormData = new FormData();

      const headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');

      for (var x = 0; x < this.fileList.length; x++) {
        let file: File = this.fileList[x];
        fd.append('fileData', file, file.name);

        let appendKeys: UploadDocumentObject = new UploadDocumentObject();
        appendKeys.id = x;
        appendKeys.LeadId = this.leadId;
        appendKeys.Description = this.model.Files[x].description;
        appendKeys.Type = this.model.Files[x].type;

        fd.append('file' + x, JSON.stringify(appendKeys));
      }
      this.http.post(`${environment.baseApiUrl}/document/${this.leadId}/files/blob/list`, fd, { headers }).subscribe(
        (result: any) => {
          this.dialogRef.close(result)
        },
        (error) => {
          console.error(error)
          this.saveInProgress = false;
          this.toastrService.danger(`Error during upload: ${error.error.Content}`)
        },
        () => {
          this.saveInProgress = false
        }
      )
    }
  }

  cancelTermsAndConditionsFileUpload() {
    this.newDocumentForm = true;
    this.model.Files = [];
    this.myFileInput.nativeElement.value = "";
  }

  editDocument(index: number) {
    this.editMode = true;
    this.editDocumentIndex = index;
    const document = this.model.Files[index];
    this.uploadForm.get('DocumentType').setValue(document.type);
    this.uploadForm.get('Description').setValue(document.description);
    this.initialValues = { DocumentType: document.type, Description: document.description };
    this.fileAttached = { name: document.name };
    this.newDocumentForm = true;
    this.cdRef.detectChanges();
  }

  checkForChanges() {
    this.hasChanges = this.uploadForm.get('DocumentType').value !== this.initialValues.DocumentType ||
                      this.uploadForm.get('Description').value !== this.initialValues.Description;
  }

  cancelEdit() {
    this.editMode = false;
    this.newDocumentForm = false;
    this.isUpdateDocument = true;
    this.uploadForm.reset();
  }
}
