import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { delay, shareReplay, debounceTime } from 'rxjs/operators'

@Injectable()
export class LayoutService {
  protected layoutSize$ = new Subject()
  protected layoutSizeChange$ = this.layoutSize$.pipe(shareReplay({ refCount: true }))
  public toggleNotificationSidenav: Subject<void> = new Subject();
  public notificationList: BehaviorSubject<any> = new BehaviorSubject(null);
  private _statusHistorySidenavVisible$ = new BehaviorSubject<boolean>(false);
  public statusHistorySidenavVisible$ = this._statusHistorySidenavVisible$.asObservable();
  private _currentEnvelopeId$ = new BehaviorSubject<string | null>(null);
  public currentEnvelopeId$ = this._currentEnvelopeId$.asObservable();

  changeLayoutSize() {
    this.layoutSize$.next()
  }

  onChangeLayoutSize(): Observable<any> {
    return this.layoutSizeChange$.pipe(delay(1))
  }

  onSafeChangeLayoutSize(): Observable<any> {
    return this.layoutSizeChange$.pipe(debounceTime(350))
  }

  openStatusHistorySidenav(envelopeId: string) {
    this._currentEnvelopeId$.next(envelopeId);
    this._statusHistorySidenavVisible$.next(true);
  }

  closeStatusHistorySidenav() {
    this._statusHistorySidenavVisible$.next(false);
    this._currentEnvelopeId$.next(null);
  }

  toggleStatusHistorySidenav() {
    this._statusHistorySidenavVisible$.next(!this._statusHistorySidenavVisible$.getValue());
  }
}
