import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { SharedService } from '../shared.service';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { BaseComponent } from 'app/@core/base/base.component';
import { Contact, contactFormActive, contactSaveObj } from 'app/pages/lead/profile/manage/customer-view-settings-form/customer-settings.model';
import { CustomRecipientEditorComponent } from 'app/pages/lead/profile/forms/custom-recipient-editor/custom-recipient-editor.component';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators'
import { LeadService } from 'app/@core/services/lead.service';
import { NoteService } from 'app/@core/services/note.service';
import { EmailValidationPipe } from '../custom-pipes/email-validation.pipe';
import { FormControl } from '@angular/forms';
import { ProceedDialogComponent } from '../proceed-dialog/proceed-dialog.component';

@Component({
  selector: 'ngx-lead-contacts-editable',
  templateUrl: './lead-contacts-editable.component.html',
  styleUrls: ['./lead-contacts-editable.component.scss']
})
export class LeadContactsEditableComponent extends BaseComponent implements OnInit {

  @Input() contactList: Array<any> = [];
  @Input() lead;
  @Input() mode: string;
  contactFormGroup: FormGroup;
  activeContactArray: contactFormActive = new contactFormActive();
  activeContact: Contact = new Contact();
  loadingContacts: boolean;
  activeIndex: number | null = null;
  saveContactInProgress: boolean = false;

  constructor(
    private _sharedService: SharedService,
    private _formBuilder: FormBuilder,
    private _dialogService: NbDialogService,
    private http: HttpClient,
    private toastrService: NbToastrService,
    private _leadService: LeadService,
    private _noteService: NoteService,
    private _toastrService: NbToastrService,
    private emailValidationPipe: EmailValidationPipe,
  ){
    super()
    this._initFormGroup();
  }

  ngOnInit(): void {
    this.loadingContacts = true;
    this.setLeadContacts();
  }

  /**
   * Function to check if the contact is primary and return button message
   */
  isContactPrimary(contact) {
    let findContact = this.contactList.find(el => el.Id === contact.get('id').value);
    return findContact && findContact.IsPrimary;
  }

  private sanitizeContactBeforeSave(contact) {
    if(contact.ContactInfo){
        return {
          Id: contact.Id,
          FirstName: contact.FirstName,
          LastName: contact.LastName,
          Position: contact.Position,
          Company: contact.Company,
          CrmId: contact.CrmId,
          PhoneNumber: contact.PhoneNumber,
          Email: contact.Email,
          ContactInfo: contact.ContactInfo.filter((x) => x.Value).map((x) => {
            return {
              Type: x.Type,
              Value: x.Value,
              Title: x.Title,
              Uid: x.Uid,
            }
          }),
        }
      }else{
        return {
          Id: contact.Id,
          FirstName: contact.FirstName,
          LastName: contact.LastName,
          Position: contact.Position,
          PhoneNumber: contact.PhoneNumber,
          Company: contact.Company,
          CrmId: contact.CrmId,
          Email: contact.Email,
          ContactInfo: contact.Fields.filter((x) => x.Value).map((x) => {
            return {
              Type: x.Type ,
              Value: x.Value,
              Title: x.Name,
            }
          }),
        }
      }
  }

  setContactAsPrimary(contact, i: number) {
    this.loadingContacts = true;
    this.saveContactInProgress = true;
    this.loadingContacts = true;
    this._leadService.primaryLeadContact(this.lead.Id, contact.get('id').value).pipe(take(1)).subscribe({
      next: (response: string) => {
        let findContactIndex = this.contactList.findIndex(el => el.Id === contact.get('id').value);
        if (findContactIndex > -1) {
          this.contactList.forEach(el => {el.IsPrimary = false});
          this.contactList[findContactIndex].IsPrimary = true;
        }
        this._toastrService.success(response);
        this.saveFilters();
        this._sharedService.refreshLeads.next();
        this.loadingContacts = false;
        this.activeIndex = null;
        this.saveContactInProgress = false;
      },
      error: (error) => {
        this._toastrService.danger(error.error.Content);
        this.loadingContacts = false;
        this.saveContactInProgress = false;
      }
    })
  }

  setLeadContacts(){
    while (this.loadingContactFormArray.length !== 0) {
      this.loadingContactFormArray.removeAt(0)
    }

    if (this.contactList) {
      this.contactList.forEach((contact) => {
        this.setActiveArrayContact(this.contactList[0]);
        this.createContactArrayEntry(contact);
      });

      if (this.contactList.length) {
        this.activeContact = this.contactList[0]
      }
    }

    this.contactFormGroup.markAsPristine();
    this.contactFormGroup.markAsUntouched();
    this.loadingContacts = false;
  }

  /**
   * Function to create contact array entry
   */
  createContactArrayEntry(contact: any): void {
    const _contact = this._formBuilder.group({
      id: contact.Id,
      firstName: contact.FirstName ? contact.FirstName : 'Unnamed',
      lastName: contact.LastName ? contact.LastName : 'Contact',
      title: contact.Position,
      email: contact.Email,
      phone: contact.PhoneNumber,
      isNew: false,
      isOpen: false,
      deleteBtn: 'Delete Contact',
      showSaveBtn: false,
    });
    this.loadingContactFormArray.push(_contact);
    this.contactFormGroup.markAsPristine();
    this.contactFormGroup.markAsUntouched();
  }

  /**
   * Function to set input width
   */
    setInputWidth(i: number, field: string): string {
      if (field == 'firstName') {
        return this.loadingContactFormArray.at(i).get('firstName').value != null ? ((this.loadingContactFormArray.at(i).get('firstName').value.length + 1) * 6) + 'px' : '135px';
      }
      if (field == 'lastName') {
        return this.loadingContactFormArray.at(i).get('lastName').value != null ? ((this.loadingContactFormArray.at(i).get('lastName').value.length + 1) * 6) + 'px' : '135px';
      }
    }

   /**
   * Function to toggle contact panel
   */
   toggleContactPanel(i: number): void {
    let _contact = this.loadingContactFormArray.controls[i];

    let expandedContactIndex;
    this.loadingContactFormArray.controls.forEach((el, i) => {
      if (el.value && el.value.isOpen) {
        expandedContactIndex = i;
      }
    })

    if (_contact.value.id && (this.validateUnsavedNewContact() || this.contactFormGroup.dirty) && expandedContactIndex != i) {
      let message = 'You have unsaved contact changes! Please save or cancel your changes'
      this._dialogService.open(ProceedDialogComponent, {
        context: {
          message: message,
          showHeader: false,
          showCancelButton: false,
          proceedButtonText: 'OK'
        }
      })
    } else {
      this.loadingContactFormArray.controls.forEach((el, i) => {
        el.get('isOpen').setValue(false);
      })

      this.loadingContactFormArray.at(i).get('isOpen').setValue(true)

      this.setActiveContact(i);
    }
  }

    /**
   * Function to handle contact input change
   */
    contactInputChange(event: any, i: number, field: string): void {
      this.activeIndex = i;
      let isPhoneValid: boolean = true;

      if (field == 'phone') {
        let val = this.loadingContactFormArray.at(i).value.phone;
        if (!val) {
          this.loadingContactFormArray.at(i).get('phone').setValue(null);
        }

        let control = this.loadingContactFormArray.at(i).get('phone');
        isPhoneValid = !control.value ? true : (control.value && String(control.value).replace(/\D/g, '').length === 10)
        control.setErrors({ numericLength: !isPhoneValid });
      }

      if (field == 'email') {
        let val = this.loadingContactFormArray.at(i).value.email;
        this.loadingContactFormArray.at(i).get('email').setErrors(
          val && this.emailValidationPipe.transform()(new FormControl(val)) ? { invalidFormat: true } : null);
      }

      let obj1 = {
        id: this.loadingContactFormArray.at(i).value.id ? this.loadingContactFormArray.at(i).value.id : null,
        firstName: this.loadingContactFormArray.at(i).value.firstName ? this.loadingContactFormArray.at(i).value.firstName : null,
        lastName: this.loadingContactFormArray.at(i).value.lastName ? this.loadingContactFormArray.at(i).value.lastName : null,
        title: this.loadingContactFormArray.at(i).value.title ? this.loadingContactFormArray.at(i).value.title : null,
        email: this.loadingContactFormArray.at(i).value.email ? this.loadingContactFormArray.at(i).value.email : null,
        phone: this.loadingContactFormArray.at(i).value.phone ? this.loadingContactFormArray.at(i).value.phone : null,
      }

      let obj2 = {
        id: this.activeContactArray.id ? this.activeContactArray.id : null,
        firstName: this.activeContactArray.firstName ? this.activeContactArray.firstName : null,
        lastName: this.activeContactArray.lastName ? this.activeContactArray.lastName : null,
        title: this.activeContactArray.position ? this.activeContactArray.position : null,
        email: this.activeContactArray.email ? this.activeContactArray.email : null,
        phone: this.activeContactArray.phone ? this.activeContactArray.phone : null,
      }

      if (this.loadingContactFormArray.at(i).get('isNew').value == true) {
        if (!obj1.firstName || !obj1.lastName || !obj1.email || !isPhoneValid) {
          this.loadingContactFormArray.at(i).get('showSaveBtn').setValue(false);
        } else {
          this.loadingContactFormArray.at(i).get('showSaveBtn').setValue(true);
        }
      } else {
        if (JSON.stringify(obj1) != JSON.stringify(obj2)) {
          if (!obj1.firstName || !obj1.lastName || !obj1.email || !isPhoneValid) {
            this.loadingContactFormArray.at(i).get('showSaveBtn').setValue(false);
          } else {
            this.loadingContactFormArray.at(i).get('showSaveBtn').setValue(true);
          }
        } else {
          this.loadingContactFormArray.at(i).get('showSaveBtn').setValue(false);
        }
      }
    }

   /**
   * Function to handle click on contact input
   */
  editableField(i: number, field: string): void {
    // this.loadingContactFormArray.at(i).value.editfullName = false;
    this.loadingContactFormArray.at(i).value.editFirstName = false;
    this.loadingContactFormArray.at(i).value.editLastName = false;
    this.loadingContactFormArray.at(i).value.editposition = false;
    this.loadingContactFormArray.at(i).value.editemail = false;
    this.loadingContactFormArray.at(i).value.editphone = false;
    this.loadingContactFormArray.at(i).value[field] = true;
  }

  /**
   * Function to validate unsaved new contact
   */
  validateUnsavedNewContact(): boolean {
    let unsavedContact: boolean = false;
    this.loadingContactFormArray.controls.forEach((el, i) => {
      if (!el.value || !el.value.id) {
        unsavedContact = true;
      }
    })

    return unsavedContact;
  }

    /**
   * Function to set active contact array
   */
    setActiveArrayContact(contact: any): void {
      this.activeContactArray.id = contact.Id;
      this.activeContactArray.firstName = contact.FirstName ? contact.FirstName : 'Unnamed',
        this.activeContactArray.lastName = contact.LastName ? contact.LastName : 'Contact',
        this.activeContactArray.position = contact.Position;
      this.activeContactArray.email = contact.Email;
      this.activeContactArray.phone = contact.PhoneNumber;
    }

      /**
   * Function to set active contact
   */
  setActiveContact(i: number): void {
    let contact = this.contactList[i];
    if (this.activeContact != contact) {
      this.contactList.forEach((x) => {
        x.editMode = false
        x.editName = false
      })
      this.activeContact = contact
    }

    if (contact) {
      this.setActiveArrayContact(contact);
    }
  }

  /**
 * Function to get loading contact form array
 */
  get loadingContactFormArray() {
    return this.contactFormGroup.get('contactsArray') as FormArray
  }

  private _initFormGroup(): void {
    this.contactFormGroup = new FormGroup({
      contactsArray: new FormArray([])
    });
  }

  saveContact(index: number) {
    this.activeIndex = index;
    let _cont = this.contactList[this.activeIndex];
    this.activeContact = this.contactList[this.activeIndex];
    this.activeContact.isCustom = _cont.isCustom;
    this.activeContact.IsSelected = _cont.IsSelected;
    this.activeContact.IsSigner =  _cont.IsSigner;

    this.activeContact.Id = this.loadingContactFormArray.at(this.activeIndex).value.id;
    this.activeContact.FirstName = this.loadingContactFormArray.at(this.activeIndex).value.firstName;
    this.activeContact.LastName = this.loadingContactFormArray.at(this.activeIndex).value.lastName;
    this.activeContact.FullName = this.activeContact.FirstName + ' ' + this.activeContact.FullName;
    this.activeContact.Position = this.loadingContactFormArray.at(this.activeIndex).value.title;
    this.activeContact.Email = this.loadingContactFormArray.at(this.activeIndex).value.email;
    this.activeContact.PhoneNumber = this.loadingContactFormArray.at(this.activeIndex).value.phone;
    this.activeContact.ContactInfo = [];

    this.saveContactInProgress = true;
    this.loadingContacts = true;
    this.loadingContactFormArray.controls.forEach((el, i) => {
      let _item = this.loadingContactFormArray.at(i).value;
      if (_item && _item.Title == 'Email') {
        this.activeContact.Email = _item.Value;
      }
      if (_item && _item.Title == 'Phone') {
        this.activeContact.PhoneNumber = _item.Value;
      }
      this.activeContact.ContactInfo.push(_item);
    })

    this.http.post(
        `${environment.baseApiUrl}/lead/${this._sharedService.activeRowLead.Id}/contact`,
        this.sanitizeContactBeforeSave(this.activeContact)
      )
      .pipe()
      .subscribe(
        (response: any) => {
          console.log(response)
          if (response) {
            this.toastrService.success('Contacts successfully updated!')
            this.saveFilters();
            this._sharedService.refreshLeads.next();
            this.saveContactInProgress = false;
            this.activeIndex = null;
            this.loadingContacts = false;
            this.loadingContactFormArray.controls[index].get('showSaveBtn').setValue(false);
          }
        },
        (error) => {
          this.toastrService.danger(error.error.Content);
          this.saveContactInProgress = false;
          this.loadingContacts = false;
        }
      )
  }

  addContact() {
    let newContact: contactSaveObj = {
      Id: 0,
      LeadId: this._sharedService.activeRowLead.Id,
      FirstName: "",
      LastName: "",
      Email: "",
      PhoneNumber: "",
      Position: "",
      IsSelected: false,
      IsSigner: false,
      IsCustom: true
    }

    this._dialogService
      .open(CustomRecipientEditorComponent, {
        context: {
          model: newContact,
        },
        closeOnEsc: false,
        closeOnBackdropClick: false
      })
      .onClose.subscribe((response) => {
        if(response) {
          this.saveFilters();
          this._sharedService.refreshLeads.next();
        }
        },
        (error) => {
          this.toastrService.danger(error.error.Content)
        })
  }

  deleteContact(contact, i:number) {
    let message = 'Are you sure you want to remove this contact?';
    this._dialogService.open(ProceedDialogComponent, {
      context: {
        message: message
      },
    }).onClose.subscribe((data) => {
      if (data) {
        let findContact = this.contactList.find(el => el.Id === contact.get('id').value);
      this.loadingContacts = true;
      this._leadService.deleteLeadContact(this.lead.Id, findContact.Id).pipe(take(1)).subscribe({
        next: (response: string) => {
          this._toastrService.success('Contact successfully removed!');
          this.loadingContactFormArray.removeAt(i);
          this.contactFormGroup.markAsPristine();
          this.contactFormGroup.markAsUntouched();
          this.loadingContacts = false;
          this.contactList.splice(i, 1);
          this.saveFilters();
          this._sharedService.refreshLeads.next();
          this.activeIndex = null;
        },
        error: (error) => {
          this._toastrService.danger(error.error.Content);
          this.loadingContacts = false;
        }
      })
      }
    })
  }

  saveFilters() {
    this.mode == 'lead' ? this._leadService.leadFilter.contactsTableExpanded = true : this._leadService.customerFilter.contactsTableExpanded = true;
    this.mode == 'lead' ? this._leadService.saveLeadFilterInLC() : this._leadService.saveCustomerFilterInLC();
  }
}
