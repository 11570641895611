import { ChangeDetectorRef, Component, OnInit, Renderer2, ViewChild, ElementRef, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-datetime-picker-custom',
  templateUrl: './datetime-picker-custom.component.html',
  styleUrls: ['./datetime-picker-custom.component.scss']
})
export class DatetimePickerCustomComponent{

  @ViewChild('taskDateTimePicker') taskDateTimePicker: any;
  patchDate: string | null = null;
  timeRanges: Array<string> = [];
  rangeIndex: number = 0;
  selectedDate: Date = null;
  date: Date = new Date();
  @ViewChild('rangeEntriesContainer', { read: ElementRef }) rangeEntriesContainer: ElementRef;
  @Input() minDate: Date;

  constructor(
    public dialogRef: NbDialogRef<DatetimePickerCustomComponent>,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private el: ElementRef
  ){
    this.generateTimeRanges();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
    if (this.patchDate) {
      this.date = new Date(this.patchDate);
      this.selectedDate = this.date;
      let time = this.patchDate.substring(this.patchDate.length - 8);
      time = time.charAt(0) === ' ' ? time = '0' + time.slice(1) : time;
      let findTimeIndex = this.timeRanges.findIndex(el => el === time);
      this.rangeIndex = findTimeIndex > -1 ? findTimeIndex : 0;
      const containerElement = this.rangeEntriesContainer.nativeElement;
      const targetElement = containerElement.children[this.rangeIndex];
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  handleDateChange(data: any) {
    this.selectedDate = this.formatDate(data);
  }

  formatDate(data) {
    const selectedHourAndMinute = this.timeRanges[this.rangeIndex];
    const [selectedHour, selectedMinute, period] = selectedHourAndMinute.match(/(\d{2}):(\d{2}) ([APMapm]{2})/).slice(1);
    console.log('TIME ', selectedHour + ' : ' + selectedMinute + ' ' + period);

    let adjustedHour = parseInt(selectedHour);
    if (period.toUpperCase() === 'PM' && adjustedHour !== 12) {
        adjustedHour += 12;
    } else if (period.toUpperCase() === 'AM' && adjustedHour === 12) {
        adjustedHour = 0;
    }

    const newTime = adjustedHour * 60 + parseInt(selectedMinute);
    const updatedDate = new Date(data);
    updatedDate.setHours(Math.floor(newTime / 60), newTime % 60);
    console.log('UPDATED TIME ', updatedDate);
    return updatedDate;
  }

  submit() {
    this.dialogRef.close(this.selectedDate);
  }

  selectRange(i: number) {
    this.rangeIndex = i;
    if (!this.selectedDate) {
      this.selectedDate = new Date();
      this.selectedDate.setHours(0, 0, 0, 0);
    }

    this.selectedDate = this.formatDate(this.selectedDate)
  }

  generateTimeRanges() {
    const startTime = new Date();
    startTime.setHours(0, 0, 0, 0);

    this.timeRanges.push("12:00 AM");
    for (let i = 0; i < 143; i++) {
      startTime.setMinutes(startTime.getMinutes() + 10);
      const formattedTime = startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      this.timeRanges.push(formattedTime);
    }
  }
}
