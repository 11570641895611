export const environment = {
  production: false,
  baseApiUrl: 'https://dev.apihubrick.techup.me/api',
  apiUrl: 'https://dev.apihubrick.techup.me',
  appVersion: '0.2.1',
  zohoEmail:{
    homePageUrl:'https://dev.hubrick.techup.me',
    redirectUrl:'https://dev.hubrick.techup.me/pages/my-profile/',
  }
}
