import { type } from '@amcharts/amcharts4/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ProposalEnvelopeRename, ProposalObject, UpdateProposalFeesObject, VendorConfirmationRequestObject } from '../models/proposal.model';
import { LeadSiteObject } from '../models/lead-site.model';
import { ProposalSiteEditorColumnDefinitionObject } from 'app/@core/models/configuraton.model';
import { FieldType } from 'app/@core/enums/field-type';

@Injectable({
  providedIn: 'root'
})
export class ProposalService {

  /**
   * Private variables
   */
  private _apiUrl: string = environment.baseApiUrl;
  _allSchemaColumns: Array<ProposalSiteEditorColumnDefinitionObject> = [
    {
      PropertyName: 'PropertyName',
      Title: 'Name',
      Format: FieldType.Text,
      ColumnId: -1,
      IsExcluded: false
    },
    {
      PropertyName: 'Address',
      Title: 'Address',
      Format: FieldType.Text,
      ColumnId: -2,
      IsExcluded: false
    },
    {
      PropertyName: 'City',
      Title: 'City',
      Format: FieldType.Text,
      ColumnId: -3,
      IsExcluded: false
    },
    {
      PropertyName: 'State',
      Title: 'State',
      Format: FieldType.Select,
      ColumnId: -4,
      IsExcluded: false
    },
    {
      PropertyName: 'ZipCode',
      Title: 'Zip Code',
      Format: FieldType.Text,
      ColumnId: -5,
      IsExcluded: false
    },
    {
      PropertyName: 'AccountNumber',
      Title: 'Account Number',
      Format: FieldType.Text,
      ColumnId: -6,
      IsExcluded: false
    },
    {
      PropertyName: 'AnnualUsage',
      Title: 'Annual Usage',
      Format: FieldType.Numeric,
      Precision: 0,
      ReadOnly: false,
      ColumnId: -7,
      IsExcluded: false
    },
    {
      PropertyName: 'EndDate',
      Title: 'End Date',
      Format: FieldType.Date,
      ColumnId: -8,
      IsExcluded: false
    }
  ];

  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Function to handle error
   * @param error
   * @returns
   */
  private _handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code. The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Function to create proposal
   */
  public createProposal(request: ProposalObject): Observable<string> {
    return this._httpClient.post<string>(`${this._apiUrl}/lead/proposal`, request).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to update proposal
   */
  public updateProposal(request: ProposalObject): Observable<string> {
    return this._httpClient.put<string>(`${this._apiUrl}/lead/proposal/status/${request.Status}/emailno/${request.VendorId}`, request).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to request confirmation from vendor
   */
  public requestConfirmationFromVendor(request: VendorConfirmationRequestObject): Observable<string> {
    return this._httpClient.post<string>(`${this._apiUrl}/lead/proposal/requestConfirmation`, request).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to get ongoing proposals
   */
  public getOngoingProposals(leadId: number): Observable<Array<ProposalObject>> {
    return this._httpClient.get<Array<ProposalObject>>(`${this._apiUrl}/lead/${leadId}/proposal/ongoing`).pipe(
      map((data: Array<ProposalObject>) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to get completed proposals
   */
  public getCompletedProposals(leadId: number): Observable<Array<ProposalObject>> {
    return this._httpClient.get<Array<ProposalObject>>(`${this._apiUrl}/lead/${leadId}/proposal/history`).pipe(
      map((data: Array<ProposalObject>) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to update proposal fees
   */
  public updateProposalFees(request: UpdateProposalFeesObject): Observable<string> {
    return this._httpClient.put<string>(`${this._apiUrl}/lead/proposal/update/fees`, request).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to delete propsal
   */
  public deleteProposal(leadId: number, proposalId: number, keepVisible: boolean): Observable<string> {
    return this._httpClient.delete<string>(`${this._apiUrl}/lead/${leadId}/proposal/${proposalId}/${keepVisible}`).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to create manual proposal
   */
  public createManualProposal(request: any): Observable<string> {
    return this._httpClient.post<string>(`${this._apiUrl}/customer/historical`, request).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to update proposal annual usage
   */
  public updateProposalAnnualUsage(id: number, request: ProposalObject): Observable<string> {
    return this._httpClient.post<string>(`${this._apiUrl}/lead/proposal/${id}`, request).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to get proposal sites
   */
  public getProposalSites(proposalId: number): Observable<Array<LeadSiteObject>> {
    return this._httpClient.get<Array<LeadSiteObject>>(`${this._apiUrl}/lead/proposal/${proposalId}/sites`).pipe(
      map((data: Array<LeadSiteObject>) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to rename proposal document name
   */
  public renameProposalDocumentName(request: ProposalEnvelopeRename): Observable<string> {
    return this._httpClient.put<string>(`${this._apiUrl}/document/rename`, request).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    );
  }
}
