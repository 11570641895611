import { HttpClient} from '@angular/common/http'
import { Component, Input, OnInit } from '@angular/core'
import { NbDialogRef } from '@nebular/theme'
import { HttpHelperService } from 'app/@core/services'
import { CoreDataSource } from 'app/@core/utils'
import { environment } from 'environments/environment'

@Component({
  selector: 'ngx-login-history',
  templateUrl: './login-history.component.html',
  styleUrls: ['./login-history.component.scss'],
})
export class LoginHistoryComponent implements OnInit {
  @Input() userName: string
  @Input() userId: number

  source: CoreDataSource
  settings = {
    selectMode: 'multi',
    hideSubHeader: true,
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    filter: false,
    columns: {
      // LoginDate: {
      //   title: 'Date',
      //   type: 'custom',
      //   renderComponent: DateViewComponent,
      // },
      LoginDate: {
        title: 'Date',
        width: '125px',
      },
      TypeBrowser: {
        title: 'Type Browser',
        width: '120px',
      },
      IPAddress: {
        title: 'IP Address',
        width: '120px',
      },
      Country: {
        title: 'Country',
        width: '100px',
      },
      City: {
        title: 'City',
        width: '100px',
      },
      Latitude: {
        title: 'Latitude',
        width: '100px',
      },
      Longitude: {
        title: 'Longitude',
        width: '100px',
      },
      Organisation: {
        title: 'Organization',
        width: '180px',
      },
      ASN: {
        title: 'ASN',
        width: '100px',
      },
      LongTimeLogin: {
        title: 'Long Time Login',
        width: '120px',
      }
    },
  }

  exportLoadingStatus: number = 0
  selectedRows: any[] = []
  isLoading: boolean
  isEmptyRows: boolean = false

  constructor(private http: HttpClient,
    private httpHelper: HttpHelperService,
    public dialogRef: NbDialogRef<LoginHistoryComponent>,
  ) { }

  ngOnInit(): void {
    this.source = new CoreDataSource(this.http, null, null, null).init({
      endPoint: `${environment.baseApiUrl}/user/${this.userId}/history`,
    })

    this.source.onChanged().subscribe((change) => {
      // Access the data array from the event's payload
      const rows = change.elements
      // Check if the rows array is empty
      this.isEmptyRows = rows.length === 0
    })
  }
  // Close Dialog
  closeDialog(): void {
    this.dialogRef.close();

  }

  // UserRowSelected Event handler
  onRowSelect(event) {
    this.selectedRows = event.selected;
  }

  // Get Selected button click handler
  onClick() {
    this.isLoading = true
    // It will delete all the selected rows
    var listrows = [];
    this.selectedRows.forEach(element => {
      listrows.push(element.Id);
    });
    var sendReq = listrows.join(",");

    this.http.delete(`${environment.baseApiUrl}/user/${this.userId}/deleteRowsHistory?listRows=${sendReq}`).subscribe((result: any) => {
      this.ngOnInit();
      this.isLoading = false
      this.selectedRows = []
    })
  }

  onExport() {
    this.exportLoadingStatus = 1
    this.httpHelper.download(`/user/history/export?userId=${this.userId}`).finally(() => {
      this.exportLoadingStatus = 0
    })
  }
}
