import { HttpClient } from '@angular/common/http'
import { Injectable, Type } from '@angular/core'
import { LocalDataSource } from 'ng2-smart-table'
import { NbDialogService } from '@nebular/theme'
import { NbToastrService } from '@nebular/theme'
import { Observable, Subject } from 'rxjs'
import { map } from 'rxjs/operators'
import { LookupService } from '../services/lookup.service'
import { ProceedDialogComponent } from 'app/pages/shared/proceed-dialog/proceed-dialog.component'

@Injectable({
  providedIn: 'root',
})
export class CoreDataSourceFactory {
  constructor(private http: HttpClient, private dialogService: NbDialogService, private toastrService: NbToastrService, private lookupService: LookupService) {}

  create(options:any) {
    return new CoreDataSource(this.http, this.dialogService, this.toastrService, this.lookupService).init(options)
  }
}

@Injectable()
export class CoreDataSource extends LocalDataSource {
  private endPoint: string
  private editorType: Type<unknown>

  loader: Subject<boolean> = new Subject()

  constructor(private http: HttpClient, private dialogService: NbDialogService, private _toastrService: NbToastrService, private lookupService: LookupService) {
    super()
  }

  init(options: any): CoreDataSource {
    this.endPoint = options.endPoint
    this.editorType = options.editorType
    this.reset()
    return this
  }

  reset(): void {
    this.http.get<any[]>(this.endPoint).subscribe((data) => {
    if (this.endPoint.includes('/lookup/documentType')) {
      data = data.filter(item => item.Name !== 'Terms and Conditions Document');
    }
      this.load(data);
      this.isLoading(false)
    })
  }

  onCreate(event): void {
    this.openEditor()
  }

  onEdit(event): void {
    this.openEditor(event.data)
  }

  isLoading(event): void{
    this.loader.next(event)
  }

  onDelete(event): void {
    let message = event.data.HasProposals ? 'Deleting this product will delete all previous proposals made with it. Are you sure you want to delete?' : 'Are you sure you want to delete?';
    this.dialogService.open(ProceedDialogComponent, {
      context: {
        message: message
      }
    }).onClose.subscribe((data) => {
      if (data) {
        this.isLoading(true)
        this.http
          .request('DELETE', this.endPoint, {
            body: event.data,
          })
          .subscribe(() => {
            this.remove(event.data)
            this.isLoading(false)
            this.refresh()
          })
      }
    })
  }

  openEditor(originalModel: any = {}) {
    this.dialogService
      .open(this.editorType, {
        context: {
          model: originalModel,
        },
        closeOnEsc: false,
        closeOnBackdropClick: false

      })
      .onClose.subscribe((model) => {
        if(this.endPoint.includes('/user')) {
          this.lookupService.is_common_pass_list_called = false;
        }
        if (!model) return

        if (originalModel.Id) {
          this.onUpdateRequest(originalModel, model);
        } else {
          this.onCreateRequest(model);
        }
      })
  }

  onUpdateRequest(originalModel, model) {
    this.isLoading(true)
    this.http.put(this.endPoint, model).subscribe((response) => {
        this.reset()
        this.isLoading(false)
      },
      (error) => {
        this._toastrService.danger(error.error.Message)
        this.resetObservable().subscribe((data) => {
          let _model = data.find(el => el.Id == model.Id)
          if (_model) {
            this.openEditor(_model)
            this.isLoading(false)
          }
        });

      }
    )
  }

  onCreateRequest(model) {
    this.isLoading(true)
    this.http.post(this.endPoint, model).subscribe((response) => {
        this.reset()
        this.isLoading(false)
      },
      (error) => {
        this._toastrService.danger(error.error.Message)
        this.openEditor(model)
        this.isLoading(false)
      }
    )
  }

  resetObservable(): Observable<any[]> {
    return this.http.get<any[]>(this.endPoint).pipe(
      map((data) => {
        this.load(data);
        this.isLoading(false);
        return data;
      })
    );
  }
}
