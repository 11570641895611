export class UserResponseObject {
  constructor(
    public Id: number | null = null,
    public FullName: string | null = null,
    public FirstName: string | null = null,
    public LastName: string | null = null,
    public Email: string | null = null,
    public PhoneNumber?: string | null,
    public UserTypeId: number | null = null,
    public UserApplicationPageIds: Array<number> | null = null,
    public IsMfaEnabled: boolean | null = null,
    public PreferredOTPType: number | null = null,
    public AllowIpWhiteListing: string | null = null,
    public IpAddressWhiteList?: string | null,
    public UseEmailNotification: boolean | null = null,
    public UseSmsNotification: boolean | null = null,
    public UsePushNotification: boolean | null = null,
  ){}
}

export class CreateUserRequestObject {
  constructor(
    public FirstName: string | null = null,
    public LastName: string | null = null,
    public Email: string | null = null,
    public Password?: string | null,
    public PhoneNumber?: string | null,
    public UserTypeId: number | null = null,
    public UserApplicationPageIds: Array<number> | null = null,
    public CustomPageAccess: boolean | null = null,
    public IsMfaEnabled: boolean | null = null,
    public PreferredOTPType: number | null = null,
    public AllowIpWhiteListing: string | null = null,
    public IpAddressWhiteList?: string | null
  ){}
}

export class UpdateUserRequestObject extends CreateUserRequestObject {
  constructor(
    public Id: number | null = null
  ){
    super();
  }
}
